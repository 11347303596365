import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CustomCheckoutModal } from "../customCheckoutModal";
import { Card } from "../card";
import { ApplePayButton } from "../../applePay";
import useShowApplePayButton from "../../hooks/useShowApplePayButton";

export const PaymentOptions = ({ activePlan, onCloseCheckout, id }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const showApplePay = useShowApplePayButton();

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const openModal = () => setModalOpen(true);

  const closeModal = () => {
    setModalOpen(false);
    onCloseCheckout?.();
  };

  return (
    <div className={styles.paymentOptionsWrapper}>
      {!isModalOpen &&
        (showApplePay ? (
          <ApplePayButton id={`paywall_continue_apple-pay_${id}`} />
        ) : (
          <Card id={`paywall_continue_${id}`} />
        ))}

      <button
        onClick={openModal}
        className={`${styles.otherPaymentButton} ${`paywall_with_other_option_${id}`}`}
      >
        CONTINIE WITH OTHER PAYMENT OPTION
      </button>
      {isModalOpen && (
        <CustomCheckoutModal onClose={closeModal} activePlan={activePlan} />
      )}
    </div>
  );
};
