import styles from "./styles.module.scss";
import gift from "@assets/img/emoji/gift.png";

export const AdditionalDiscount = () => {
  return (
    <div className={styles.additionalDiscount}>
      <p className={styles.previousDiscount}>
        Previous discount: <span className={styles.oldDiscount}>60%</span>
      </p>
      <div className={styles.discountOffer}>
        <div className={styles.icon}>
          <img src={gift} alt="Gift Icon" />
        </div>
        <p className={styles.offerText}>
          Get your personal plan with up to{" "}
          <span className={styles.newDiscount}>71% </span>discount
        </p>
      </div>
    </div>
  );
};
