import React, { useEffect, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useFetchPlans } from "../../hooks/useFetchPlans";
import styles from "./styles.module.scss";
import { Button } from "@common/index";
import { Lock2 } from "@assets/Icons";
import StripeCardElements from "../stripeCardElements";
import { useNavigate } from "react-router";
import { successToast, errorToast } from "@utils/toaster";
import { CardLogos } from "../cardLogos/index";
import { axiosInstance } from "@api";
import { useSelector } from "react-redux";
import { updateDataLayer } from "../../utils/updateDataLayer";

export const Card = ({ isInModal, id }) => {
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user.userInfo);

  const { initiatePayment, activePlan } = useFetchPlans();

  useEffect(() => {
    if (!error) {
      return;
    }
    errorToast({ message: error });
  }, [error]);

  const handleChange = (event) => {
    setDisabled(event.empty || !event.complete);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setError(null);

    try {
      const { stripeClientSecret } = await initiatePayment({
        paymentMethod: "CREDIT_CARD",
        paymentProvider: "STRIPE",
      });
      const payload = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: { card: elements.getElement("cardNumber") },
      });

      console.log(payload);

      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setSucceeded(false);
        return;
      } else {
        setError(null);
        setSucceeded(true);
      }
      await axiosInstance.post("/auth/send-pin-to-email", {
        email: user?.email,
      });
      updateDataLayer({
        paymentMethod: "CREDIT_CARD",
        plan: activePlan.title,
        currency: activePlan.currency,
        price: +activePlan.priceAmount / 100,
      });

      if (process.env.REACT_APP_PRODUCT === "SOMATIC") {
        navigate("/upsale");
      } else {
        navigate("/activate");
      }
      successToast({ message: "Payment successful! Thank you!" });
    } catch (error) {
      setError(error.response.data.message);
      setSucceeded(false);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className={styles.cardWrapper}>
      {!isInModal && (
        <>
          <div className={styles.guarantee}>
            <p>30-day money-back guarantee</p>
          </div>
          <CardLogos />
        </>
      )}
      <form id="payment-form" onSubmit={handleSubmit} className={styles.form}>
        <StripeCardElements onChange={handleChange} />
        <div className={styles.control}>
          <Button
            className={id}
            onClick={(e) => console.log(e.target)}
            disabled={processing || disabled || succeeded}
          >
            {processing ? (
              <div className={styles.spinner}></div>
            ) : (
              <>
                <Lock2 className={id} />
                <p className={id}>CONTINUE</p>
              </>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
